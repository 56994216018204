import React, { FC, ReactNode, useState } from 'react';
import cx from 'classnames';

import './c-checklist-item.scss';

enum KEY_CODES {
  ENTER = 13,
  SPACE = 32,
}

type Props = {
  className?: string;
  title: ReactNode;
  description?: ReactNode;
  checked?: boolean;
  id: string;
  onClick?: JSX.IntrinsicElements['div']['onClick'];
  onKeyPress?: JSX.IntrinsicElements['div']['onKeyPress'];
  onKeyboardClick?: JSX.IntrinsicElements['div']['onKeyPress'];
};

const CheckListItem: FC<Props> = ({
  className,
  id,
  title,
  description,
  checked,
  onClick,
  onKeyPress,
  onKeyboardClick,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(checked || false);

  const toggleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleCheckBoxClick: JSX.IntrinsicElements['svg']['onClick'] = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling up to the item div
    setIsChecked(!isChecked);
  };

  const handleKeyPress: JSX.IntrinsicElements['div']['onKeyPress'] = (event) => {
    // toggle dropdown when press enter or space
    if (event.charCode === KEY_CODES.ENTER || event.charCode === KEY_CODES.SPACE) {
      event.currentTarget.click();

      if (event.charCode === KEY_CODES.SPACE) {
        event.preventDefault();
      }

      if (onKeyboardClick) {
        onKeyboardClick(event);
      }
    }

    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={cx('accordion-item c-checklist-item', { open: isExpanded }, className)}
      tabIndex={0}
      onClick={toggleAccordion}
      onKeyPress={handleKeyPress}
      aria-expanded={isExpanded}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      id={id}
    >
      <div className="c-checklist-item__checkbox" aria-hidden>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          className={`checkbox-icon ${isChecked ? 'checked' : ''}`}
          onClick={handleCheckBoxClick}
        >
          <g fill="none" fillRule="evenodd" strokeWidth="3">
            {isChecked && <path stroke="#05cbd0" d="M6 11.402l2.874 2.934L16.06 7"></path>}
            <circle cx="11" cy="10" r="10" stroke="#05cbd0"></circle>
          </g>
        </svg>
      </div>
      <div className="c-checklist-item__content">
        <div className="header" role="button" tabIndex={0} id={`${id}-title`}>
          <p>{title}</p>
          <div className={`arrow ${isExpanded ? 'open' : ''}`}>
            {isExpanded ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11">
                <path fill="none" stroke="#05cbd0" strokeWidth="2" d="M2 9l7.5-7.57 7.5 7.57" strokeLinecap="square" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="11">
                <path fill="none" stroke="#05cbd0" strokeWidth="2" d="M2 2l7.5 7.57L17 2" strokeLinecap="square" />
              </svg>
            )}
          </div>
        </div>
        <div className={`body ${isExpanded ? 'open' : ''}`}>
          {description && (
            <p className="c-checklist-item__description" id={`${id}-description`}>
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckListItem;
